<template>
  <div>
     <img style="width:100%" src="../../assets/img/prodcut-4.png"/>
  </div>
</template>

<script>
import { getSwiper } from "@/api/index";
import annunciate from "@/components/annunciate";
import productList from "./components/productIndex";
import companyProfile from "./components/companyInfo";
import supportInfo from "./components/supportInfo";
import industryInformation from "./components/industryInformation";
import mapView from "./components/map";
import mapPcView from "./components/mapPc";
import footerView from "./components/footer";
import join from "./components/Join";
import { getFeatureContent } from "@/api/index";
export default {
  props: ["mennu", "isPc"],
  name: "contact",
  components: {
    productList,
    companyProfile,
    supportInfo,
    industryInformation,
    mapView,
    footerView,
    annunciate,
    mapPcView,
    join
  },
  data() {
    return {
      productMenu: [],
      swiperData: [],
      annunciateData: []
    };
  },
  mounted() {},

  methods: {
    initInfo() {
      let menu = localStorage.getItem("mobileMenueInfo");
      menu = menu ? JSON.parse(menu) : menu;
      if (menu.length) {
        let productMenu = menu.find(e => e.title === "产品中心");
        this.productMenu = productMenu ? productMenu.children : [];
        this.getFeatureList("dynamic");
      }
    },
    getSwiper() {
      getSwiper().then(res => {
        if (res.success) {
          this.swiperData = res.result;
        }
      });
    },
    getFeatureList(name) {
      let navigationId = "";
      // 目前站点id写死
      switch (name) {
        case "fireworks":
          navigationId = "1305773792458575872";
          break;
        case "vintageWine":
          navigationId = "1305773902303203328";
          break;
        case "wedding":
          navigationId = "1305774020066676736";
          break;
        case "industry-news":
          navigationId = "1305778760947208192";
          break;
        case "dynamic":
          navigationId = "1305778580990595072";
          break;
      }
      const params = {
        pageNumber: 1,
        pageSize: 8,
        sort: "time",
        order: "asc",
        navigationId: navigationId
      };
      getFeatureContent(params).then(res => {
        if (res.success) {
          if (name === "industry-news") {
            this.industryNews = res.result.content;
          } else if (name === "dynamic") {
            this.annunciateData = res.result.content;
          } else {
            this.productList.forEach(ele => {
              if (ele.path === name) {
                ele.content = res.result;
              }
            });
          }
        }
      });
    },
    checkIsFcm() {
      let fcm = this.$route.query.fcm;
      fcm &&
        this.$router.push({
          path: "/anti-fake",
          query: { fcm: fcm }
        });
    }
  },

  created() {
    this.checkIsFcm();
    this.getSwiper();
  },
  watch: {
    mennu: {
      handler(val) {
        val && this.initInfo();
      },
      immediate: true
    }
  }
};
</script>

<style lang="less" scoped>
@import "./index.less";
.index-wrap {
  text-align: center;
  background-color: #f7f7f7;
  .swiper {
    /deep/ img {
      width: 100% !important;
    }
  }
  .join-phone-wrap {
    height: 180px;
    background-color: #ffffff;
    box-shadow: 0px 3px 10px 0px rgba(191, 206, 222, 0.2);
    border-radius: 20px;
    display: flex;
    padding: 0 88px 0 110px;
    justify-content: space-between;
    .number {
      flex: 1;
      display: flex;
      img {
        width: 87px;
        height: 135px;
        margin-right: 34px;
      }
      .rexian {
        font-size: 36px;
        line-height: 30px;
        color: #c90f2c;
      }
      .jiamen {
        display: inline-block;
        font-size: 48px;
        font-weight: bold;
        line-height: 30px;
        color: #333333;
        margin: 38px 0 23px;
      }
    }
    .click-btn {
      width: 270px;
      height: 88px;
      background-color: #c90f2c;
      border-radius: 41px;
      font-size: 30px;
      margin-top: 48px;
      font-weight: bold;
      line-height: 88px;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
  .support-info,
  .company-profile,
  .product-list,
  .industry-information,
  .map-view {
    margin-top: 20px;
  }
  .map-view-pc {
    background-color: #fff;
  }
}
</style>
